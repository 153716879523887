// ローディング判定
jQuery(window).on("load", function() {
  jQuery("body").attr("data-loading", "true");
});

$(function() {
  "use waits";

  var dialog = document.getElementById("dialog");
  var waits = document.getElementsByClassName("wait");
  var cancel = document.getElementById("cancel");
  var scrollpos;
  //ボタンがクリックされたらダイアログを表示する

  for (let i = 0; i < waits.length; i++) {
    waits[i].addEventListener(
      "click",
      function() {
        dialog.style.visibility = "visible";
        scrollpos = $(window).scrollTop();
        $("body")
          .addClass("fixed")
          .css({ top: -scrollpos });
      },
      false
    );
  }

  cancel.addEventListener("click", function() {
    $("body")
      .removeClass("fixed")
      .css({ top: 0 });
    window.scrollTo(0, scrollpos);
    dialog.style.visibility = "hidden";
  });
});

jQuery(window).load(function() {
  $.when(
    //ここに先に終わらせたい処理
    jQuery("body").fadeIn(0),
    jQuery("#top-bg").fadeIn(500)
  ).done(function() {
    //後に実行したい処理
    jQuery("#top-main").fadeIn(500);
    new WOW().init();
  });
});

jQuery(function() {
  // スクロール判定
  jQuery(window).on("scroll", function() {
    let scrollTop = jQuery(this).scrollTop();
    let windowHeight = jQuery(this).height();
    let documentHeight = jQuery(document).height();

    if (100 < scrollTop) {
      jQuery("body").attr("data-scroll", "true");
    } else {
      jQuery("body").attr("data-scroll", "false");
    }

    if (documentHeight - (windowHeight + scrollTop) <= 0) {
      jQuery("body").attr("data-scroll-bottom", "true");
    } else {
      jQuery("body").attr("data-scroll-bottom", "false");
    }
  });

  /* ドロワー */
  jQuery(".js-drawer").on("click", function(e) {
    e.preventDefault();
    let targetClass = jQuery(this).attr("data-target");
    let ariaControls = jQuery(this).attr("aria-controls");
    jQuery("." + targetClass).toggleClass("is-checked");
    jQuery(body).toggleClass("is-checked");

    if (jQuery("#" + ariaControls).attr("aria-hidden") === "true") {
      jQuery("#" + ariaControls).attr("aria-hidden", "false");
    } else {
      jQuery("#" + ariaControls).attr("aria-hidden", "ture");
    }

    if (jQuery(this).attr("aria-expanded") === "true") {
      jQuery(this).attr("aria-expanded", "false");
    } else {
      jQuery(this).attr("aria-expanded", "ture");
    }
    return false;
  });

  /* スムーススクロール */
  jQuery('a[href^="#"]').click(function() {
    let header = jQuery("#header").height();
    let speed = 300;
    let id = jQuery(this).attr("href");
    let target = jQuery("#" == id ? "html" : id);
    let position = jQuery(target).offset().top - header;
    if ("fixed" !== jQuery("#header").css("position")) {
      position = jQuery(target).offset().top;
    }
    if (0 > position) {
      position = 0;
    }
    jQuery("html, body").animate(
      {
        scrollTop: position
      },
      speed
    );
    return false;
  });

  /* 電話リンク */
  let ua = navigator.userAgent;
  if (ua.indexOf("iPhone") < 0 && ua.indexOf("Android") < 0) {
    jQuery('a[href^="tel:"]')
      .css("cursor", "default")
      .on("click", function(e) {
        e.preventDefault();
      });
  }
});

$(function() {
  $("._mv-ttl").textillate({
    //繰り返し
    loop: false,
    // アニメーションの間隔時間
    minDisplayTime: 3000,
    // アニメーション開始までの遅延時間
    initialDelay: 100,
    // アニメーションの自動スタート
    autoStart: true,

    // 開始時のアニメーション設定
    in: {
      // エフェクトの指定
      effect: "fadeIn",
      // 遅延時間の指数
      delayScale: 1.3,
      // 文字ごとの遅延時間
      delay: 50,
      // true:アニメーションをすべての文字に同時適用
      sync: false,
      // true:文字表示がランダムな順に表示される
      shuffle: false
    },

    // 終了時のアニメーション設定
    out: {}
  });

  $("._mv-logo").textillate({
    //繰り返し
    loop: false,
    // アニメーションの間隔時間
    minDisplayTime: 3000,
    // アニメーション開始までの遅延時間
    initialDelay: 1000,
    // アニメーションの自動スタート
    autoStart: true,

    // 開始時のアニメーション設定
    in: {
      // エフェクトの指定
      effect: "fadeIn",
      // 遅延時間の指数
      delayScale: 1.5,
      // 文字ごとの遅延時間
      delay: 70,
      // true:アニメーションをすべての文字に同時適用
      sync: false,
      // true:文字表示がランダムな順に表示される
      shuffle: false
    },

    // 終了時のアニメーション設定
    out: {}
  });

  $("._mv-txt").textillate({
    //繰り返し
    loop: false,
    // アニメーションの間隔時間
    minDisplayTime: 3000,
    // アニメーション開始までの遅延時間
    initialDelay: 300,
    // アニメーションの自動スタート
    autoStart: true,

    // 開始時のアニメーション設定
    in: {
      // エフェクトの指定
      effect: "fadeInUp",
      // 遅延時間の指数
      delayScale: 1.5,
      // 文字ごとの遅延時間
      delay: 20,
      // true:アニメーションをすべての文字に同時適用
      sync: false,
      // true:文字表示がランダムな順に表示される
      shuffle: false
    },

    // 終了時のアニメーション設定
    out: {}
  });
});
